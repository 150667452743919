<div class="p-10 rounded-xl overflow-y-auto" (keydown.enter)="submit()">
    <!-- Dialog title -->
    <h5 class="text-lg font-semibold mb-4 text-gray-900">Assign contact to customer</h5>
    <h5 class="text-base font-medium mb-4 text-gray-700" *ngIf="err">An error occurred.</h5>

    <!-- Dialog Form -->
    <div>
        <form [formGroup]="addOrganisationForm">
            <div class="w-full mt-1">

                <!-- Customer -->
                <div class="mb-4 w-full">
                    <label for="customerSelection" class="block text-sm font-medium leading-6 text-gray-900">Assignee
                        (customer)</label>
                    <select id="customerSelection" formControlName="customerSelection"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6 overflow-y-auto"
                        required>
                        <option *ngFor="let customer of customers" [value]="customer._id">{{ customer.organisationName
                            }}</option>
                    </select>
                </div>


            </div>
        </form>

        <!-- Dialog Actions -->
        <div class="flex justify-end space-x-2 mt-4">
            <button type="button"
                class="border border-gray-300 text-gray-700 rounded-md px-4 py-2 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                (click)="onNoClick()">Cancel</button>
            <button type="button"
                class="bg-blue-600 text-white rounded-md px-4 py-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                (click)="submit()">Add</button>
        </div>
    </div>
</div>

<!-- <mat-dialog-content>
    <h5>Assign contact to customer</h5>


    <form class="w-100">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Assignee</mat-label>
            <input type="text" matInput [matAutocomplete]="auto" />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                    {{ option.organisationName }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </form>
</mat-dialog-content> -->

<!-- <mat-dialog-actions>
    <button mat-button [mat-dialog-close]>Cancel</button>
    <span class="spacer"></span>
    <button mat-raised-button color="primary" (click)="submit()">
        Add
    </button>
</mat-dialog-actions> -->