<!-- <mat-dialog-content>
    <h5>Move Contract to New Customer</h5>
    <mat-form-field appearance="outline" class="w-100 mt-2">
        <mat-label>Select target customer</mat-label>
        <mat-select [formControl]="targetCustomer">
            <mat-option *ngFor="let customer of customers" [value]="customer._id">
                {{ customer.organisationName }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</mat-dialog-content> -->

<div class="p-10 rounded-xl overflow-y-auto" (keydown.enter)="submit()">
    <!-- Dialog title -->
    <h5 class="text-lg font-semibold mb-4 text-gray-900">Move contract</h5>

    <!-- Target Organisation -->
    <div class="mb-4 w-full">
        <label for="targetCustomer" class="block text-sm font-medium leading-6 text-gray-900">Select target
            customer</label>
        <select id="targetCustomer" [formControl]="targetCustomer"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6 overflow-y-auto"
            required>
            <option *ngFor="let customer of customers" [value]="customer._id">{{ customer.organisationName }}
            </option>
        </select>
    </div>

    <!-- Dialog Actions -->
    <div class="flex justify-end space-x-2 mt-8">
        <button type="button"
            class="border border-gray-300 text-gray-700 rounded-md px-4 py-2 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            (click)="onNoClick()">Cancel</button>
        <button type="button"
            class="bg-blue-600 text-white rounded-md px-4 py-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            (click)="submit()" [disabled]="!targetCustomer.value">Move</button>
    </div>
</div>
<!-- <mat-dialog-actions>
    <button mat-button [mat-dialog-close]>Cancel</button>
    <span class="spacer"></span>
    <button mat-flat-button color="primary" (click)="submit()" [disabled]="!targetCustomer.value">
        Move
    </button>
</mat-dialog-actions> -->