import { AppHttpInterceptor } from "./interceptors/response.interceptor";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatListModule } from "@angular/material/list";
import { MatIconModule } from "@angular/material/icon";
import {
  ProductsComponent,
  NewProductDialogComponent,
} from "./pages/products/products.component";
import {
  MsalModule,
  MsalInterceptor,
  MsalService,
  MsalRedirectComponent,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuard,
  MsalInterceptorConfiguration,
  MsalGuardConfiguration,
  MsalBroadcastService,
} from "@azure/msal-angular";
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { Config } from "./config/config";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { MatButtonModule } from "@angular/material/button";
import {
  CustomersComponent,
  NewCustomerDialogComponent,
  ConfirmCustomerDeleteDialogComponent
} from "./pages/customers/customers.component";
import { PageParamsComponent } from "./partials/page-params/page-params.component";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatTableModule } from "@angular/material/table";
import { CdkTableModule } from "@angular/cdk/table";
import {
  CustomerComponent,
  CreateContractDialogComponent,
  ContractDeleteConfirmationComponent,
  ContactManageDialogComponent,
  MoveContractDialogComponent,
} from "./pages/customer/customer.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatStepperModule } from "@angular/material/stepper";
import {
  ContractComponent,
  NewInspectionDialogComponent,
  ManageContractContactDialogComponent,
  MoveInspectionDialogComponent,
  ManageEditContractDialogComponent
} from "./pages/contract/contract.component";
import { MatSelectModule } from "@angular/material/select";
import { ContactBoxComponent } from "./pages/contract/partials/contact-box/contact-box.component";
import { ContractDetailsBoxComponent } from "./pages/contract/partials/contract-details-box/contract-details-box.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import {
  InspectionComponent,
  NewNoteDialogComponent,
  ManageGenerateReportWarningDialogComponent,
  ManageEditDetailsDialogComponent,
  AddGenericNoteToReportDialogComponent,
  SendConfirmationDialogComponent,
  DeleteReportDialogComponent, 
  ConfirmInspectionDeleteDialogComponent,
  ChangeAuditTypeDialogComponent,
} from "./pages/inspection/inspection.component";
import { InspectionInfoComponent } from "./pages/inspection/partials/inspection-info/inspection-info.component";
import { MatChipsModule } from "@angular/material/chips";
import {
  InspectionDocumentsComponent,
} from "./pages/inspection/partials/inspection-documents/inspection-documents.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatTreeModule } from "@angular/material/tree";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatPaginatorModule } from "@angular/material/paginator";
import { ProductComponent, EditProductDetailsDialogComponent, EditLoadTestDetailsDialogComponent, DeleteProductDialogComponent, EditGliderCountDetailsDialogComponent } from "./pages/product/product.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { ScopesComponent } from "./pages/scopes/scopes.component";
import {
  ScopeManagementDialog,
  ScopesTableComponent,
} from "./pages/scopes/partials/scopes-table/scopes-table.component";
import { MatMenuModule } from "@angular/material/menu";
import { MatCheckboxModule } from "@angular/material/checkbox";
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarModule,
} from "@angular/material/snack-bar";
import {
  InternalNotesComponent,
} from "./pages/inspection/partials/internal-notes/internal-notes.component";
import {
  ReportNotesComponent,
  NewReportNoteDialogComponent,
} from "./pages/inspection/partials/report-notes/report-notes.component";
import { InspectionPricingContainerComponent } from "./pages/inspection/partials/inspection-pricing-container/inspection-pricing-container.component";
import { MatCardModule } from "@angular/material/card";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatSortModule } from "@angular/material/sort";
import {
  CommentsComponent,
  NewCommentDialogComponent,
} from "./pages/comments/comments.component";
import { InspectionRequestsComponent } from "./pages/inspection-requests/inspection-requests.component";
import { RequestsTableComponent } from "./pages/inspection-requests/components/requests-table/requests-table.component";
import { InspectionAdditionalParametersComponent } from "./pages/inspection/partials/inspection-additional-parameters/inspection-additional-parameters.component";
import { MetricContainerComponent } from "./pages/dashboard/partials/metric-container/metric-container.component";
import { WhatsNewBoxComponent } from "./partials/whats-new-box/whats-new-box.component";
import { ParametersComponent, ManageEditParametersDialogComponent, ManageAddUsageDialogComponent } from "./pages/parameters/parameters.component";
import { SysErrorDialogComponent } from "./sysDialogs/sys-error-dialog/sys-error-dialog.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ToolbarComponent } from "./pages/dashboard/partials/toolbar/toolbar.component";
import { MatBadgeModule } from "@angular/material/badge";
import {
  ContractRemindersComponent,
  ManualReminderConfirmationDialogComponent,
} from "./pages/dashboard/partials/contract-reminders/contract-reminders.component";
import { GraphContainerComponent } from "./pages/dashboard/partials/graph-container/graph-container.component";
import {
  ContactsComponent,
  CreateNewContactDialogComponent,
} from "./pages/contacts/contacts.component";
import { ContactComponent, AddOrganisationToContact } from "./pages/contact/contact.component";
import { MatGridListModule } from "@angular/material/grid-list";
import { LayoutModule } from "@angular/cdk/layout";
import { TrackUsagesManagerComponent } from "./pages/parameters/partials/track-usages-manager/track-usages-manager.component";
import { InvoiceManagerComponent, AddInvoiceDialog, EditInvoiceDialog } from "./pages/invoice-manager/invoice-manager.component";
import { MatRadioModule } from "@angular/material/radio";
import {
  AuditSpecManagerComponent,
  CreateAuditSpecDialogComponent,
} from "./pages/audit-spec-manager/audit-spec-manager.component";
import { AuditSpecEditorComponent, AddProductToSpecDialogComponent } from "./pages/audit-spec-editor/audit-spec-editor.component";
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication,
} from "@azure/msal-browser";
import { OverlayModule } from "@angular/cdk/overlay";
import { SearchComponent } from "./partials/search/search.component";
import {
  AuditsLoadingSpinnerComponent,
  AuditTableComponent,
} from "./pages/contract/partials/audit-table/audit-table.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { MatTabsModule } from "@angular/material/tabs";
import { ErrorComponent } from "./partials/snackbars/error/error.component";
import { DeleteConfirmationComponent } from "./sysDialogs/delete-confirmation/delete-confirmation.component";
import { AuditManagementComponent } from "./pages/audit-management/audit-management.component";
import { CreateReportNoteModalComponent } from "./pages/audit-management/dialogs/create-report-note.modal/create-report-note.modal.component";
import { NgxPaginationModule } from "ngx-pagination";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { NgIconsModule } from "@ng-icons/core";
import {
  heroArchiveBox,
  heroArrowsUpDown,
  heroAtSymbol,
  heroBuildingOffice2,
  heroCalendar,
  heroCheckCircle,
  heroCog8Tooth,
  heroCube,
  heroDeviceTablet,
  heroExclamationCircle,
  heroQuestionMarkCircle,
  heroShieldCheck,
  heroTableCells,
  heroTrash,
  heroUserCircle,
  heroWrench,
  heroXCircle,
  heroPencil,
  heroArrowDownOnSquare,
  heroDocumentChartBar,
  heroBookmark,
  heroSquaresPlus,
  heroPlus,
  heroArrowPath,
  heroFolderPlus,
  heroArrowRightEndOnRectangle,
  heroArrowDownTray,
  heroEllipsisVertical,
  heroMinusCircle,
  heroChatBubbleBottomCenterText,
  heroChartBar,
  heroPaperAirplane,
  heroExclamationTriangle,
  heroUserMinus,
  heroXMark,
  heroMagnifyingGlass,
  heroInformationCircle,
  heroArrowTopRightOnSquare,
  heroArrowRightOnRectangle,
  heroShieldExclamation
} from "@ng-icons/heroicons/outline";
import { CdkMenuModule } from "@angular/cdk/menu";
import { ManageEditCustomerDialogComponent } from "./pages/customer/dialogs/edit-customer-dialog/edit-customer-dialog";
import { CdkAccordionModule } from "@angular/cdk/accordion";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";


const protectedResourceMap = new Map<string, Array<string>>();

protectedResourceMap.set("https://graph.microsoft.com/v1.0", [
  "user.read",
  "User.ReadBasic.All",
  "User.Read.All",
  "User.ReadWrite.All",

  "Directory.Read.All",
  "Directory.ReadWrite.All",
  "Directory.AccessAsUser.All",

  "Mail.Read",
  "Mail.Read.Shared",
  "Mail.Send",
  "Mail.Send.Shared",
  "Mail.Send",
  "Mail.ReadWrite",
]);

protectedResourceMap.set("http://localhost:3000/", [
  "api://f0f507d4-ac2a-4f49-9665-891050f2bd6f/safetrakAPI",
]);
protectedResourceMap.set("https://api.safer-track.com", [
  "api://f0f507d4-ac2a-4f49-9665-891050f2bd6f/safetrakAPI",
]);

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: "f0f507d4-ac2a-4f49-9665-891050f2bd6f",
      authority:
        "https://login.microsoftonline.com/customgroupltd.onmicrosoft.com",
      redirectUri: Config.msal.redirect_uri.getRedirectURI(),
      postLogoutRedirectUri: "http://localhost:4200",
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ["user.read"],
    },
    loginFailedRoute: "./login-failed",
  };
}

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;


@NgModule({
  declarations: [
    AppComponent,
    ProductsComponent,
    DashboardComponent,
    CustomersComponent,
    PageParamsComponent,
    CustomerComponent,
    ContractComponent,
    CreateContractDialogComponent,
    ContactBoxComponent,
    ContractDetailsBoxComponent,
    NewInspectionDialogComponent,
    InspectionComponent,
    InspectionInfoComponent,
    InspectionDocumentsComponent,
    ProductComponent,
    EditProductDetailsDialogComponent,
    EditLoadTestDetailsDialogComponent,
    DeleteProductDialogComponent,
    NewProductDialogComponent,
    ScopesComponent,
    ScopesTableComponent,
    SendConfirmationDialogComponent,
    InternalNotesComponent,
    ReportNotesComponent,
    InspectionPricingContainerComponent,
    NewReportNoteDialogComponent,
    CommentsComponent,
    InspectionRequestsComponent,
    RequestsTableComponent,
    NewCommentDialogComponent,
    InspectionAdditionalParametersComponent,
    MetricContainerComponent,
    WhatsNewBoxComponent,
    ContractDeleteConfirmationComponent,
    ContactManageDialogComponent,
    ManageContractContactDialogComponent,
    ManageEditContractDialogComponent,
    ManageGenerateReportWarningDialogComponent,
    ManageEditDetailsDialogComponent,
    NewNoteDialogComponent,
    DeleteReportDialogComponent,
    ConfirmInspectionDeleteDialogComponent,
    ParametersComponent,
    ManageEditParametersDialogComponent,
    ManageAddUsageDialogComponent,
    SysErrorDialogComponent,
    ToolbarComponent,
    ContractRemindersComponent,
    ManualReminderConfirmationDialogComponent,
    GraphContainerComponent,
    ContactsComponent,
    ContactComponent,
    AddOrganisationToContact,
    ScopeManagementDialog,
    MoveInspectionDialogComponent,
    TrackUsagesManagerComponent,
    MoveContractDialogComponent,
    InvoiceManagerComponent,
    AddInvoiceDialog,
    EditInvoiceDialog,
    AuditSpecManagerComponent,
    CreateAuditSpecDialogComponent,
    AuditSpecEditorComponent,
    AddProductToSpecDialogComponent,
    SearchComponent,
    CreateNewContactDialogComponent,
    ChangeAuditTypeDialogComponent,
    NewCustomerDialogComponent,
    ConfirmCustomerDeleteDialogComponent,
    NewProductDialogComponent,
    AuditTableComponent,
    AuditsLoadingSpinnerComponent,
    ErrorComponent,
    DeleteConfirmationComponent,
    AuditManagementComponent,
    CreateReportNoteModalComponent,
    AddGenericNoteToReportDialogComponent,
    ManageEditCustomerDialogComponent,
    EditGliderCountDetailsDialogComponent,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  imports: [
    CommonModule,
    MsalModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    MatTableModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatSelectModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatChipsModule,
    MatListModule,
    MatAutocompleteModule,
    MatTreeModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatCardModule,
    ReactiveFormsModule,
    FormsModule,
    MatSortModule,
    MatTooltipModule,
    MatBadgeModule,
    MatGridListModule,
    LayoutModule,
    MatRadioModule,
    OverlayModule,
    NgxSkeletonLoaderModule,
    MatTabsModule,
    NgxChartsModule,
    CdkTableModule,
    NgIconsModule.withIcons({
      heroTableCells,
      heroCheckCircle,
      heroAtSymbol,
      heroXCircle,
      heroExclamationCircle,
      heroQuestionMarkCircle,
      heroTrash,
      heroArchiveBox,
      heroArrowsUpDown,
      heroCalendar,
      heroBuildingOffice2,
      heroUserCircle,
      heroCube,
      heroCog8Tooth,
      heroShieldCheck,
      heroWrench,
      heroDeviceTablet,
      heroPencil,
      heroArrowDownOnSquare,
      heroDocumentChartBar,
      heroBookmark,
      heroSquaresPlus,
      heroPlus,
      heroArrowPath,
      heroFolderPlus,
      heroArrowRightEndOnRectangle,
      heroArrowDownTray,
      heroEllipsisVertical,
      heroMinusCircle,
      heroChatBubbleBottomCenterText,
      heroChartBar,
      heroPaperAirplane,
      heroExclamationTriangle,
      heroUserMinus,
      heroXMark,
      heroMagnifyingGlass,
      heroInformationCircle,
      heroArrowTopRightOnSquare,
      heroArrowRightOnRectangle,
      heroShieldExclamation,
    }),
    NgxPaginationModule,
    CdkMenuModule,
    CdkAccordionModule,
    NgxMatSelectSearchModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalGuard,
    MsalService,
    MsalBroadcastService,
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
    // ThemeService,
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 4000 },
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule { }
