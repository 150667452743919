<div class="p-10 rounded-xl overflow-y-auto" (keydown.enter)="submit()">
    <!-- Dialog title -->
    <h5 class="text-lg font-semibold mb-4 text-gray-900" *ngIf="section == 'inspection'">Edit Inspection Details</h5>
    <div>
        <form [formGroup]='inspectionDetailsForm' *ngIf="section == 'inspection'">
            <!-- Inspection Date -->
            <div class="mb-2">
                <label class="block text-sm font-medium leading-6 text-gray-900" for="inspectionDate">Date</label>
                <mat-form-field appearance="outline" class="w-full no-border">
                    <input matInput [matDatepicker]="picker" id="inspectionDate" formControlName="inspectionDate"
                        required
                        class="block w-full h-full rounded-md p-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 placeholder:border-0 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>

            <!-- Auditor -->
            <div class="mb-4">
                <label class="block text-sm font-medium leading-6 text-gray-900">Auditor</label>
                <input type="text" id="auditor" formControlName="auditor" required
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6">
            </div>

            <!-- Internal Reference -->
            <div class="mb-4">
                <label class="block text-sm font-medium leading-6 text-gray-900">Internal Reference</label>
                <input type="text" id="internalReference" formControlName="internalReference" required
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6">
            </div>
        </form>

        <form [formGroup]='pricingForm' *ngIf="section == 'pricing'">

            <!-- Logging Rate -->
            <div class="mb-4">
                <label for="loggingRate" class="block text-sm font-medium leading-6 text-gray-900">Logging Rate</label>
                <div class="relative rounded-md shadow-sm">
                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">£</span>
                    </div>
                    <input type="text" name="loggingRate" id="loggingRate"
                        class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="0.00" aria-describedby="loggingRate-currency" formControlName="loggingRate"
                        required>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <span class="text-gray-500 sm:text-sm" id="loggingRate-currency">GBP</span>
                    </div>
                </div>
            </div>

            <!-- loadTestRate -->
            <div class="mb-4">
                <label for="loadTestRate" class="block text-sm font-medium leading-6 text-gray-900">Load Test
                    Rate</label>
                <div class="relative rounded-md shadow-sm">
                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">£</span>
                    </div>
                    <input type="text" name="loadTestRate" id="loadTestRate"
                        class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="0.00" aria-describedby="loadTestRate-currency" formControlName="loadTestRate"
                        required>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <span class="text-gray-500 sm:text-sm" id="loadTestRate-currency">GBP</span>
                    </div>
                </div>
            </div>

            <!-- gliderCountRate -->
            <div class="mb-4">
                <label for="gliderCountRate" class="block text-sm font-medium leading-6 text-gray-900">Glider Count
                    Rate</label>
                <div class="relative rounded-md shadow-sm">
                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">£</span>
                    </div>
                    <input type="text" name="gliderCountRate" id="gliderCountRate"
                        class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="0.00" aria-describedby="gliderCountRate-currency" formControlName="gliderCountRate"
                        required>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <span class="text-gray-500 sm:text-sm" id="loggingRate-currency">GBP</span>
                    </div>
                </div>
            </div>

            <!-- additionalLabourRate -->
            <div class="mb-4">
                <label for="additionalLabourRate" class="block text-sm font-medium leading-6 text-gray-900">Labour
                    Rate</label>
                <div class="relative rounded-md shadow-sm">
                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">£</span>
                    </div>
                    <input type="text" name="additionalLabourRate" id="additionalLabourRate"
                        class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="0.00" aria-describedby="additionalLabourRate-currency"
                        formControlName="additionalLabourRate" required>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <span class="text-gray-500 sm:text-sm" id="additionalLabourRate-currency">GBP</span>
                    </div>
                </div>
            </div>

            <!-- fixedFee -->
            <div class="mb-4">
                <label for="fixedFee" class="block text-sm font-medium leading-6 text-gray-900">Fixed
                    Fee</label>
                <div class="relative rounded-md shadow-sm">
                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">£</span>
                    </div>
                    <input type="text" name="fixedFee" id="fixedFee"
                        class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="0.00" aria-describedby="fixedFee-currency" formControlName="fixedFee" required>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <span class="text-gray-500 sm:text-sm" id="fixedFee-currency">GBP</span>
                    </div>
                </div>
            </div>
        </form>

        <form [formGroup]='additionalParametersForm' *ngIf="section == 'additionalParameters'">
            <!-- Additional Labour Hours -->
            <div class="mb-4">
                <label class="block text-sm font-medium leading-6 text-gray-900">Additional Labour Hours</label>
                <input type="text" id="additionalLabourHours" formControlName="additionalLabourHours" required
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6">
            </div>

            <!-- Additional Part Price -->
            <div class="mb-4">
                <label class="block text-sm font-medium leading-6 text-gray-900">Additional Part Price</label>
                <input type="text" id="additionalPartsPrice" formControlName="additionalPartsPrice" required
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6">
            </div>
        </form>
    </div>

    <!-- Dialog Actions -->
    <div class="flex justify-end space-x-2 mt-4">
        <button type="button"
            class="border border-gray-300 text-gray-700 rounded-md px-4 py-2 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            (click)="onNoClick()">Cancel</button>
        <button type="button"
            class="bg-blue-600 text-white rounded-md px-4 py-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            (click)="submit()">Submit</button>
    </div>
</div>