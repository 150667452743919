<div class="p-10 rounded-xl overflow-y-auto" (keydown.enter)="submit()">
    <!-- Dialog title -->
    <h5 class="text-lg font-semibold mb-4 text-gray-900">Edit Parameters</h5>
    <div>
        <form [formGroup]='parametersForm'>
            <!-- Maximum Weight in Child Room-->
            <div class="mb-4">
                <label for="maximumWeight" class="block text-sm font-medium leading-6 text-gray-900">Weight Limit
                    (kg)</label>
                <div class="relative rounded-md shadow-sm">
                    <input type="text" name="maximumWeight" id="maximumWeight"
                        class="block w-full rounded-md border-0 py-1.5 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="0.00" aria-describedby="maximumWeight-currency" formControlName="maximumWeight"
                        required>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <span class="text-gray-500 sm:text-sm" id="maximumWeight-currency">kg</span>
                    </div>
                </div>
            </div>
        </form>

    </div>

    <!-- Dialog Actions -->
    <div class="flex justify-end space-x-2 mt-4">
        <button type="button"
            class="border border-gray-300 text-gray-700 rounded-md px-4 py-2 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            (click)="onNoClick()">Cancel</button>
        <button type="button"
            class="bg-blue-600 text-white rounded-md px-4 py-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            (click)="submit()">Submit</button>
    </div>

</div>