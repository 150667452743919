<div class="p-10 rounded-xl overflow-y-auto" (keydown.enter)="submit()">
    <h5 class="text-base font-medium my-4 text-gray-700">You have already sent a report to this customer, are you sure that you want to proceed?</h5>

    <div class="bg-white py-4 sm:px-3">
        <dt class="text-sm text-left font-medium leading-6 text-gray-800">List of sent reports</dt>
        <dd class="mt-1 text-sm text-left leading-6 text-gray-700">
            <ul>
                <li *ngFor="let report of reportsWithNotifications" class="py-2">
                    <div class="flex w-full items-center justify-center">
                        <div class="mr-5 flex items-center ">
                            <ng-icon name="heroDocumentChartBar" size="18" class="text-gray-500"></ng-icon>
                        </div>
        
                        <div class="w-full text-sm font-medium text-gray-500 leading-normal">
                            <div class="text-gray-800 flex items-center">
                                <span>{{ report.name }}</span>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </dd>
    </div>

    <mat-checkbox class="example-margin" [formControl]="confirmation" color="primary"> <span class="body-1">I want to
            generate another report.</span></mat-checkbox>

    <!-- Dialog Actions -->
    <div class="flex justify-end space-x-2 mt-4">
        <button type="button"
            class="border border-gray-300 text-gray-700 rounded-md px-4 py-2 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            (click)="onNoClick()">Cancel</button>
        <div class="rounded-md"
            [ngClass]="{'bg-blue-400 hover:bg-blue-400': !confirmation.value, 'bg-blue-600 hover:bg-indigo-500': confirmation.value}">
            <button type="button"
                class="text-white px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                [disabled]="!confirmation.value" (click)="submit()">
                Generate Report</button>
        </div>
    </div>
</div>