<div class="p-10 rounded-xl overflow-y-auto">
    <!-- Dialog title -->
    <h5 class="text-lg font-semibold mb-4 text-gray-900">Edit Inspection Details</h5>
    <div>
        <form [formGroup]='productForm'>

            <!-- Product -->
            <div class="mb-4">
                <label class="block text-sm font-medium leading-6 text-gray-900">Product</label>
                <input type="text" id="product" formControlName="product" required
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6">
            </div>

            <!-- Manufacturer -->
            <div class="mb-4">
                <label class="block text-sm font-medium leading-6 text-gray-900">Manufacturer</label>
                <input type="text" id="manufacturer" formControlName="manufacturer" required
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6">
            </div>

            <!-- Description -->
            <div class="mb-4">
                <label class="block text-sm font-medium leading-6 text-gray-900">Description</label>
                <textarea #noteTextarea type="text" id="description" formControlName="description"
                    style="min-height: 18rem"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6"></textarea>
            </div>
        </form>
    </div>

    <!-- Dialog Actions -->
    <div class="flex justify-end space-x-2 mt-4">
        <button type="button"
            class="border border-gray-300 text-gray-700 rounded-md px-4 py-2 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            (click)="onNoClick()">Cancel</button>
        <button type="button"
            class="bg-blue-600 text-white rounded-md px-4 py-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            (click)="submit()">Submit</button>
    </div>
</div>