<div class="bg-white">

  <!-- Breadcrumbs -->
  <nav class="flex pb-8 sm:px-6 lg:px-8 nav-bar" aria-label="Breadcrumb">
    <ol role="list" class="flex items-center space-x-4">
      <li>
        <div>
          <a routerLink="/" class="navIcon text-gray-400 hover:text-gray-500">
            <svg class="h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                clip-rule="evenodd" />
            </svg>
            <span class="sr-only">Home</span>
          </a>
        </div>
      </li>
      <li>
        <div class="flex items-center">
          <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd" />
          </svg>
          <a routerLink="/customers"
            class="navIcon-last ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Customers</a>
        </div>
      </li>
    </ol>
  </nav>


  <div class="flex flex-wrap items-center gap-6 px-4 py-3 sm:flex-nowrap sm:px-6 lg:px-8">
    <h1 class="font-semibold text-xl leading-7 text-gray-900">Customers</h1>
  </div>


  <div>
    <div *ngIf="err" class="bg-red-100 text-red-700 border border-red-400 rounded px-4 py-2 mb-4">
      {{ err.status }} | {{ err.message }}
    </div>
  </div>

  <!-- Main toolbar -->
  <div>
    <!-- Loading spinner -->
    <div *ngIf="loading" class="flex items-center space-x-2 px-4 sm:px-6 lg:px-8 mt-3">
      <mat-spinner mode="indeterminate" [diameter]="20"></mat-spinner>
      <span>Loading customers</span>
    </div>

    <!-- Customers Table -->
    <div>
      <div class="p-1 flex justify-between px-4 py-2 sm:flex-nowrap sm:px-6 lg:px-8">
        <div class="relative max-w-3xl">
          <div class="absolute inset-y-0 start-0 flex items-center ps-2 pointer-events-none">
            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
              fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
            </svg>
          </div>
          <input type="search" id="default-search" [formControl]="filterControl"
            class="block w-full p-2 ps-8 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-cg-primary focus:border-sky-950 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Filter..." required />
        </div>
        <button (click)="newCustomer()"
          class="ml-auto flex items-center gap-x-1 rounded-md bg-cg-primary px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
          <ng-icon name='heroPlus' size='18' class='text-white'></ng-icon>
          New customer
        </button>
      </div>

      <table cdk-table [dataSource]="dataSource" cdkSort class="min-w-full divide-y divide-gray-300 bg-white mt-4">

        <!-- Organisation Name -->
        <ng-container cdkColumnDef="organisationName">
          <th cdk-header-cell *cdkHeaderCellDef cdk-sort-header
            class="py-3.5 text-left text-sm font-semibold text-gray-900 gpl-4 sm:pl-6 lg:px-8">Name</th>
          <td cdk-cell *cdkCellDef="let customer" class="align-top py-3 pl-4 sm:pl-6 lg:px-8">
            <div>
              <a routerLink="/customers/customer/{{customer._id}}"
                class="text-sm font-medium text-gray-900 leading-normal">{{
                customer.organisationName }}</a> <br>
              <div *ngIf="customer.annualContract"
                class="whitespace-nowrap text-sm text-emerald-400 flex items-center pt-1 leading-normal">
                <ng-icon name="heroCheckCircle" class="mr-1 text-emerald-400" size="18"></ng-icon>
                <span>Annual Contract</span>
              </div>
            </div>
          </td>
        </ng-container>

        <!-- Contact -->
        <ng-container cdkColumnDef="contact">
          <th cdk-header-cell *cdkHeaderCellDef class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            Contact</th>
          <td cdk-cell *cdkCellDef="let customer" class="align-top py-3">
            <div *ngIf="customer.primaryContact">
              <p class="whitespace-nowrap text-sm text-gray-700 font-medium leading-normal">{{
                customer.primaryContact.name_first }}
                {{customer.primaryContact.name_last}}</p>
              <div class="flex items-center space-x-1 mt-1 leading-normal text-sm">
                <ng-icon name="heroAtSymbol" class="text-gray-500" size="14"></ng-icon>
                <span>{{ customer.primaryContact.email}}</span>
              </div>

              <div *ngIf="customer.primaryContact?.accountActive"
                class="flex items-center space-x-1 mt-1 leading-normal text-sm">
                <ng-icon name="heroCheckCircle" class="text-green-500" size="18"></ng-icon>
                <span>Last Login: <span *ngIf="customer.primaryContact.lastLogin">{{customer.primaryContact.lastLogin |
                    date:"dd/MM/y"}}</span><span *ngIf="!customer.primaryContact.lastLogin">Never</span></span>
              </div>
            </div>
          </td>
        </ng-container>

        <!-- Address -->
        <ng-container cdkColumnDef="address">
          <th cdk-header-cell *cdkHeaderCellDef class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            Address</th>
          <td cdk-cell *cdkCellDef="let customer" class="align-top py-3">
            <p class="whitespace-nowrap text-sm text-gray-700 font-medium leading-normal">{{
              customer.address.address_line_1 }}</p>
            <p class="whitespace-nowrap text-sm text-gray-700 leading-normal">{{ customer.address.town }}, {{
              customer.address.postcode }}</p>
          </td>
        </ng-container>

        <!-- Contracts -->
        <ng-container cdkColumnDef="contracts">
          <th cdk-header-cell *cdkHeaderCellDef class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            Contracts</th>
          <td cdk-cell *cdkCellDef="let customer" class="py-3 align-top">
            <div *ngIf="customer.validContracts.length > 0" class="flex items-center space-x-2 text-sm text-gray-500">
              <span
                class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">{{
                customer.validContracts.length }} valid</span>

            </div>
            <div *ngIf="customer.expiredContracts.length > 0" class="flex items-center space-x-2 text-sm text-gray-500">
              <span
                class="inline-flex items-center rounded-md bg-red-50 px-2 py-1 mt-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">{{
                customer.expiredContracts.length }} expired</span>
            </div>
            <div *ngIf="customer.expiringSoonContracts.length > 0"
              class="flex items-center space-x-2 text-sm text-gray-500">
              <span
                class="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 mt-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20">{{
                customer.expiringSoonContracts.length }} soon</span>
            </div>
          </td>
        </ng-container>

        <!-- Actions -->
        <ng-container cdkColumnDef="view">
          <th cdk-header-cell *cdkHeaderCellDef class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></th>
          <td cdk-cell *cdkCellDef="let customer" class="py-3 align-top items-end flex">
            <button [matMenuTriggerFor]="menu"
              class="hover:bg-gray-200 rounded-full p-2 h-8 w-8 flex items-center justify-center">
              <span class="sr-only">Open options</span>
              <ng-icon name="heroEllipsisVertical" size="18" class="text-gray-500"></ng-icon>
            </button>

            <mat-menu #menu="matMenu" class="inline-flex flex-col min-w-40 bg-white rounded-md">              <button cdk-menu-item (click)="deleteCustomer(customer._id, customer.organisationName)"
              class="action-button px-3 py-3 text-sm leading-6 text-gray-900 hover:bg-gray-100 flex flex-row items-center justify-center">
              <ng-icon name="heroTrash" class="text-red-500 mr-2" size="18"></ng-icon>

              Delete Customer
            </button>
            </mat-menu>
          </td>
        </ng-container>


        <!-- Rows -->
        <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
        <tr cdk-row *cdkRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator [length]="paginationLength" [pageSize]="20" [pageIndex]="pageIndex"
        (page)="handlePageEvent($event)" showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>
</div>