import { Component, ElementRef, HostListener, OnInit, ViewChild } from "@angular/core";
import { FormControl, UntypedFormControl } from "@angular/forms";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { SearchService } from "../../services/search/search.service";
import { Router } from "@angular/router";

interface ISearchResults {
  contacts: [],
  contracts: [],
  customers: [],
  inspections: [],
}

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
  @ViewChild('commandPalette') commandPalette!: ElementRef;
  public searchField = new UntypedFormControl();
  public isOpen = false;
  public searchResults: ISearchResults = {
    contacts: [],
    contracts: [],
    customers: [],
    inspections: [],
  };

  constructor(private searchService: SearchService, private router: Router) { }

  ngOnInit(): void {
    this.searchField.valueChanges
      .pipe(debounceTime(150), distinctUntilChanged())
      .subscribe((res) => {
        if (res && res.length > 0) {
          this.isOpen = true;
          this.searchService
            .searchContracts(res)
            .then((results: ISearchResults) => {
              this.searchResults = results;
              console.log(this.searchResults.inspections)
            })
            .catch((err) => console.error(err));
        } else {
          this.isOpen = false;
        }
      });
  }

  onSearchInput() {
    if (this.searchField.value.length > 0) {
      this.isOpen = true;
    } else {
      this.isOpen = false;
    }
  }

  onFocusInput() {
    if (this.searchField.value.length > 0) {
      this.isOpen = true;
    } else {
      this.isOpen = false;
    }

  }

  // Close palette if clicked outside
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (this.isOpen && this.commandPalette && !this.commandPalette.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  goToContact(id: string) {
    this.router.navigate(["/contacts/contact", id]);
    this.isOpen = false;
    this.searchField.reset();
  }

  goToContract(id: string) {
    this.router.navigate(["/contract", id]);
    this.isOpen = false;
    this.searchField.reset();
  }
  
  goToCustomer(id: string) {
    this.router.navigate(["/customers/customer", id]);
    this.isOpen = false;
    this.searchField.reset();
  }

  goToInspection(id: string) {
    this.router.navigate(["/inspections", id]);
    this.isOpen = false;
    this.searchField.reset();
  }
}
