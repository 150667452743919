<div class="p-10 rounded-xl overflow-y-auto" (keydown.enter)="submit()">
    <!-- Dialog title -->
    <h5 class="text-lg font-semibold mb-4 text-gray-900">Edit Load Test Details</h5>
    <div>
        <form [formGroup]='loadTestForm'>

            <!-- Weight Separation Vertical Minimum -->
            <div class="mb-4">
                <label for="weightSeparationVerticalMinimum"
                    class="block text-sm font-medium leading-6 text-gray-900">Weight Separation Vertical Minimum</label>
                <div class="relative rounded-md shadow-sm">
                    <input type="text" name="weightSeparationVerticalMinimum" id="weightSeparationVerticalMinimum"
                        class="block w-full rounded-md border-0 py-1.5 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="0.00" aria-describedby="weightSeparationVerticalMinimum-currency"
                        formControlName="weightSeparationVerticalMinimum" required>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <span class="text-gray-500 sm:text-sm" id="weightSeparationVerticalMinimum-weight">KG</span>
                    </div>
                </div>
            </div>

            <!-- Weight Separation Vertical Maximum -->
            <div class="mb-4">
                <label for="weightSeparationVerticalMaximum"
                    class="block text-sm font-medium leading-6 text-gray-900">Weight Separation Vertical Maximum</label>
                <div class="relative rounded-md shadow-sm">
                    <input type="text" name="weightSeparationVerticalMaximum" id="weightSeparationVerticalMaximum"
                        class="block w-full rounded-md border-0 py-1.5 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="0.00" aria-describedby="weightSeparationVerticalMaximum-weight"
                        formControlName="weightSeparationVerticalMaximum" required>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <span class="text-gray-500 sm:text-sm" id="weightSeparationVerticalMaximum-weight">KG</span>
                    </div>
                </div>
            </div>

            <!-- Weight Separation Angled Minimum -->
            <div class="mb-4">
                <label for="weightSeparationAngleMinimum"
                    class="block text-sm font-medium leading-6 text-gray-900">Weight Separation Angled Minimum</label>
                <div class="relative rounded-md shadow-sm">
                    <input type="text" name="weightSeparationAngleMinimum" id="weightSeparationAngleMinimum"
                        class="block w-full rounded-md border-0 py-1.5 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="0.00" aria-describedby="weightSeparationAngleMinimum-currency"
                        formControlName="weightSeparationAngleMinimum" required>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <span class="text-gray-500 sm:text-sm" id="weightSeparationAngleMinimum-weight">KG</span>
                    </div>
                </div>
            </div>

            <!-- Weight Separation Angled Maximum -->
            <div class="mb-4">
                <label for="weightSeparationAngleMaximum"
                    class="block text-sm font-medium leading-6 text-gray-900">Weight Separation Angled Maximum</label>
                <div class="relative rounded-md shadow-sm">
                    <input type="text" name="weightSeparationAngleMaximum" id="weightSeparationAngleMaximum"
                        class="block w-full rounded-md border-0 py-1.5 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="0.00" aria-describedby="weightSeparationAngleMaximum-currency"
                        formControlName="weightSeparationAngleMaximum" required>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <span class="text-gray-500 sm:text-sm" id="weightSeparationAngleMaximum-weight">KG</span>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <!-- Dialog Actions -->
    <div class="flex justify-end space-x-2 mt-4">
        <button type="button"
            class="border border-gray-300 text-gray-700 rounded-md px-4 py-2 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            (click)="onNoClick()">Cancel</button>
        <button type="button"
            class="bg-blue-600 text-white rounded-md px-4 py-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            (click)="submit()">Submit</button>
    </div>
</div>