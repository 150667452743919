<div class="flex justify-center items-center min-h-full min-w-full" *ngIf="generateReportLoading">
  <svg class="animate-spin h-10 w-10 text-cg-primary" xmlns="http://www.w3.org/2000/svg" fill="none"
    viewBox="0 0 24 24">
    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C6.48 0 0 6.48 0 12h4z"></path>
  </svg>
</div>

<div class="bg-white sm:flex-nowrap sm:px-6 lg:px-8" *ngIf="inspection && !generateReportLoading">
  <!-- Breadcrumbs -->
  <nav class="flex pb-8" aria-label="Breadcrumb">
    <ol role="list" class="flex items-center space-x-4">
      <li>
        <div>
          <a routerLink="/" class="navIcon text-gray-400 hover:text-gray-500">
            <svg class="h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                clip-rule="evenodd" />
            </svg>
            <span class="sr-only">Home</span>
          </a>
        </div>
      </li>
      <li>
        <div class="flex items-center">
          <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd" />
          </svg>
          <a routerLink="/customers"
            class="navIcon ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Customers</a>
        </div>
      </li>
      <li>
        <div class="flex items-center">
          <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd" />
          </svg>
          <a class="navIcon ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
            [routerLink]="['/customers/customer', inspection.contract.customer._id]"
            [state]="{customer: inspection.contract.customer}"
            aria-current="page">{{inspection.contract.customer.organisationName}}</a>
        </div>
      </li>
      <li>
        <div class="flex items-center">
          <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd" />
          </svg>
          <a class="navIcon ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
            [routerLink]="['/contract', inspection.contract._id]" [state]="{contract: inspection.contract}"
            aria-current="page">{{inspection.contract.name}}</a>
        </div>
      </li>
      <li>
        <div class="flex items-center">
          <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd" />
          </svg>
          <a class="navIcon-last ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
            aria-current="page">{{inspection.internalReference}}</a>
        </div>
      </li>
    </ol>
  </nav>

  <div class="flex items-center justify-between mt-3">
    <h1 class="font-semibold text-xl leading-7 text-gray-900">{{inspection.internalReference}}</h1>

    <div class="flex items-center gap-4 h-auto">
      <button [matMenuTriggerFor]="menu"
        [disabled]="inspection.additionalLabourTime === null || inspection.additionalPartPrice === null"
        class="ml-auto flex items-center gap-x-1 rounded-md bg-cg-primary px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
        <ng-icon name="heroArrowDownOnSquare" size="18" class="text-white"></ng-icon>
        Generate Report
      </button>
      <mat-menu #menu="matMenu" class="report-menu">
        <button (click)="openGenerateReportWarningDialog(true)"
          class="ml-auto items-left gap-x-1 px-2 py-1 text-sm font-semibold">
          With Invoice
        </button>

        <button (click)="openGenerateReportWarningDialog(false)"
          class="ml-auto items-left gap-x-1 px-2 py-1 text-sm font-semibold">
          Without Invoice
        </button>
      </mat-menu>

      <button (click)="generateMeasurementReport()"
        class="ml-auto flex items-center gap-x-1 rounded-md bg-cg-primary px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
        <ng-icon name="heroDocumentChartBar" size="18" class="text-white"></ng-icon>
        Measurement Report
      </button>

      <button (click)="markComplete()" *ngIf="!inspection.complete"
        class="ml-auto flex items-center gap-x-1 rounded-md bg-cg-primary px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">

        <ng-icon name="heroBookmark" size="18" class="text-white"></ng-icon>
        Mark Complete
      </button>

      <button (click)="markPending()" *ngIf="inspection.complete"
        class="ml-auto flex items-center gap-x-1 rounded-md bg-cg-primary px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
        <ng-icon name="heroBookmark" size="18" class="text-white"></ng-icon>
        Mark Editable
      </button>

      <button [matMenuTriggerFor]="moreMenu"
        class="action-button over:bg-gray-200 rounded-full p-2 h-8 w-8 flex items-center justify-center">
        <span class="sr-only">Open options</span>
        <ng-icon name="heroEllipsisVertical" size="18" class="text-gray-500"></ng-icon>
      </button>

      <mat-menu #moreMenu="matMenu" class="inline-flex flex-col min-w-40 bg-white rounded-md">
        <button cdk-menu-item (click)="openChangeAuditTypeDialog()"
          class="action-button w-full px-3 py-3 gap-2 text-sm leading-6 text-gray-900 hover:bg-gray-100 flex flex-row items-center justify-start">
          <ng-icon name="heroArrowPath" size="18" class="mr-2"></ng-icon>
          <span>Change inspection type</span>
        </button>
        <button cdk-menu-item (click)="generateBlueprint()"
          class="action-button w-full px-3 py-3 gap-2 text-sm leading-6 text-gray-900 hover:bg-gray-100 flex flex-row items-center justify-start">
          <ng-icon name="heroFolderPlus" size="18" class="mr-2"></ng-icon>
          <span>Create Blueprint</span>
        </button>
        <button cdk-menu-item (click)="openDeleteInspectionDialog()"
          class="action-button w-full px-3 py-3 gap-2 text-sm leading-6 text-gray-900 hover:bg-gray-100 flex flex-row items-center justify-start">
          <ng-icon name="heroTrash" class="text-red-500 mr-2" size="18"></ng-icon>
          <span>Delete Inspection</span>
        </button>
      </mat-menu>
    </div>
  </div>


  <div class="grid grid-cols-12 mb-8">

    <!-- Inspection Details Info -->
    <div class="col-span-12 mt-6">
      <dl>
        <div class="bg-white py-2 sm:grid sm:grid-cols-6 sm:gap-0 sm:px-3 min-h-10">
          <dt class="text-base text-left font-semibold leading-7 text-gray-900">Inspection details</dt>
          <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-1 sm:mt-0 sm:-ml-1">
            <button (click)="openEditDetailsDialog('inspection')"
              class="button-small hover:bg-gray-200 rounded-full"><ng-icon name="heroPencil" size="18"
                class="text-gray-500"></ng-icon></button>
          </dd>
        </div>
      </dl>
    </div>

    <div class="col-span-6">
      <dl>
        <!-- Customer -->
        <div class="bg-white py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
          <dt class="text-sm text-left font-medium leading-6 text-gray-800">Customer</dt>
          <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <p>{{ inspection.contract.customer.organisationName }}</p>
          </dd>
        </div>
        <!-- Contract -->
        <div class="bg-white py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
          <dt class="text-sm text-left font-medium leading-6 text-gray-800">Contract</dt>
          <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <p>{{ inspection.contract.name }}</p>
          </dd>
        </div>
      </dl>
    </div>

    <div class="col-span-6">
      <dl>
        <!-- Status -->
        <div class="bg-white py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
          <dt class="text-sm text-left font-medium leading-6 text-gray-800">Status</dt>
          <dd class="mt-1 text-sm text-left leading-6 sm:col-span-2 sm:mt-0">
            <mat-chip *ngIf="inspection.complete" class="small-chip bg-green-100">
              <span class="text-green-500">Complete</span>
            </mat-chip>
            <mat-chip *ngIf="!inspection.complete" class="small-chip bg-red-100">
              <span class="text-red-500">Incomplete</span>
            </mat-chip>
          </dd>
        </div>

        <!-- Inspection type -->
        <div class="bg-white py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
          <dt class="text-sm text-left font-medium leading-6 text-gray-800">Inspection Type</dt>
          <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <p>{{ parseInspectionType(inspection.auditType) }}</p>
          </dd>
        </div>
      </dl>
    </div>

    <div class="col-span-6">
      <dl>
        <!-- Date -->
        <div class="bg-white py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
          <dt class="text-sm text-left font-medium leading-6 text-gray-800">Date</dt>
          <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <p *ngIf="!editing">{{ inspection.date | date}}</p>
            <div *ngIf="editing">
              <mat-form-field appearance="outline" class="no-border custom-form-field max-h-2">
                <input matInput [matDatepicker]="picker" id="inspectionDate" [formControl]="inspectionDate"
                  placeholder="Inspection Date"
                  class="block rounded-md p-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 placeholder:border-0 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm max-h-2">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
          </dd>
        </div>

        <!-- Auditor -->
        <div class="bg-white py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
          <dt class="text-sm text-left font-medium leading-6 text-gray-800">Auditor</dt>
          <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <p *ngIf="!editing">{{ inspection.auditor }}</p>
          </dd>
        </div>
      </dl>
    </div>

    <div class="col-span-6">
      <dl>
        <!-- Internal Reference -->
        <div class="bg-white py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
          <dt class="text-sm text-left font-medium leading-6 text-gray-800">Internal Reference</dt>
          <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <p *ngIf="!editing">{{ inspection.internalReference }}</p>
          </dd>
        </div>

        <!-- Reference -->
        <div class="bg-white py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
          <dt class="text-sm text-left font-medium leading-6 text-gray-800">Reference</dt>
          <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <p *ngIf="!editing">{{ inspection.reference }}</p>
          </dd>
        </div>
      </dl>
    </div>
  </div>

  <hr>

  <!-- Pricing Info -->
  <div class="grid grid-cols-12 mb-8">

    <div class="col-span-12 mt-6">
      <dl>

        <div class="bg-white py-2 sm:grid sm:grid-cols-6 sm:gap-0 sm:px-3 min-h-10">
          <dt class="text-base text-left font-semibold leading-7 text-gray-900">Pricing</dt>
          <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-1 sm:mt-0 sm:-ml-1">
            <button (click)="openEditDetailsDialog('pricing')"
              class="button-small hover:bg-gray-200 rounded-full"><ng-icon name="heroPencil" size="18"
                class="text-gray-500"></ng-icon></button>
          </dd>
        </div>
      </dl>
    </div>

    <div class="col-span-6">
      <dl>
        <!-- Logging Rate -->
        <div class="bg-white py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
          <dt class="text-sm text-left font-medium leading-6 text-gray-800">Logging rate</dt>
          <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <p>£{{ inspection.loggingRate }}</p>
          </dd>
        </div>

        <!-- Load Test Rate -->
        <div class="bg-white py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
          <dt class="text-sm text-left font-medium leading-6 text-gray-800">Load Test Rate</dt>
          <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <p>£{{ inspection.loadTestRate }}</p>
          </dd>
        </div>
      </dl>
    </div>

    <div class="col-span-6">
      <dl>
        <!-- Glider Count Rate -->
        <div class="bg-white py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
          <dt class="text-sm text-left font-medium leading-6 text-gray-800">Glider Count Rate</dt>
          <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <p>£{{ inspection.gliderCountRate }}</p>
          </dd>
        </div>

        <!-- Labour Rate -->
        <div class="bg-white py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
          <dt class="text-sm text-left font-medium leading-6 text-gray-800">Labour Rate</dt>
          <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <p>£{{ inspection.additionalLabourRate }}</p>
          </dd>
        </div>
      </dl>
    </div>

    <div class="col-span-6">
      <dl>
        <!-- Fixed Fee -->
        <div class="bg-white py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
          <dt class="text-sm text-left font-medium leading-6 text-gray-800">Fixed Fee</dt>
          <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <p>£{{ inspection.fixedFee }}</p>
          </dd>
        </div>

        <!-- Custom Line Items -->
        <div class="bg-white py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
          <dt class="text-sm text-left font-medium leading-6 text-gray-800">Custom Line Items</dt>
          <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <p>{{ inspection.invoice.length }}</p>
          </dd>
        </div>
      </dl>
    </div>

    <div class="col-span-6">
      <dl>
        <!-- Edit Invoice -->
        <div class="bg-white py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
          <dt class="text-sm text-left font-medium leading-6 text-gray-800">Edit Invoice</dt>
          <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <button mat-stroked-button color="primary" (click)="goToInvoiceEditor()" class="mx-1 action-button">Go to
              Invoice
              Editor</button>
          </dd>
        </div>
      </dl>
    </div>
  </div>

  <hr>

  <!-- Additional Parameters -->
  <div class="grid grid-cols-12 mb-8">

    <!-- Additional Parameters -->
    <div class="col-span-12 mt-6">
      <dl>
        <div class="bg-white py-2 sm:grid sm:grid-cols-6 sm:gap-0 sm:px-3 min-h-10">
          <dt class="text-base text-left font-semibold leading-7 text-gray-900">Additional Parameters</dt>
          <dd class="text-sm text-left leading-6 text-gray-700 sm:col-span-1 sm:mt-0 sm:-ml-1">
            <button (click)="openEditDetailsDialog('additionalParameters')"
              class="p-3 button-small hover:bg-gray-200 rounded-full">
              <ng-icon name="heroPencil" size="18" class="text-gray-600"></ng-icon>
            </button>
          </dd>
        </div>
      </dl>
    </div>

    <div class="col-span-6">
      <dl>
        <!-- Additional Labour Hours -->
        <div class="bg-white py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
          <dt class="text-sm text-left font-medium leading-6 text-gray-800">Additional Labour Hours</dt>
          <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <p>{{ inspection.additionalLabourTime }}</p>
          </dd>
        </div>
      </dl>
    </div>

    <div class="col-span-6">
      <dl>
        <!-- Additional Parts Price -->
        <div class="bg-white py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
          <dt class="text-sm text-left font-medium leading-6 text-gray-800">Additional Parts Price</dt>
          <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <p>{{ inspection.additionalPartPrice }}</p>
          </dd>
        </div>
      </dl>
    </div>
  </div>

  <hr>

  <!-- Internal Notes -->
  <div class="grid grid-cols-12 mb-8">

    <div class="col-span-12 mt-6">
      <dl>
        <div class="bg-white py-2 sm:grid sm:grid-cols-6 sm:gap-0 sm:px-3 min-h-10">
          <dt class="text-base text-left font-semibold leading-7 text-gray-900">Internal Notes</dt>
          <dd class="mt-3 text-sm text-left leading-6 text-gray-700 sm:col-span-1 sm:mt-0 sm:-ml-1">
            <button (click)="openNewInternalNoteDialog('internal')" class="button-small hover:bg-gray-200 rounded-full">
              <ng-icon name='heroPlus' size='18' class='text-gray-500'></ng-icon>
            </button>
          </dd>


        </div>

        <div class="bg-white py-2 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-3 min-h-10">
          <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <p *ngIf="inspection.internalNotes.length === 0">There are no internal notes</p>
            <ul>
              <li *ngFor="let note of inspection.internalNotes" class="flex py-2">

                <div class="mr-5">
                  <ng-icon name="heroChatBubbleBottomCenterText" size="18" class="text-gray-500"></ng-icon>
                </div>

                <span class="text-sm font-medium text-gray-700 leading-normal">{{ note }}</span>

                <span class="self-end ml-auto">
                  <button (click)="deleteNote(note, 'internal')"
                    class="hover:bg-gray-200 rounded-full p-2 h-8 w-8 flex items-center justify-center">
                    <ng-icon name="heroTrash" size="18" class="text-gray-500"></ng-icon>
                  </button>
                </span>
              </li>
            </ul>
          </dd>
        </div>

      </dl>
    </div>
  </div>

  <hr>

  <!-- Report Notes -->
  <div class="grid grid-cols-12 mb-8">

    <div class="col-span-12 mt-6">
      <dl>
        <div class="bg-white py-2 sm:grid sm:grid-cols-6 sm:gap-0 sm:px-3 min-h-10">
          <dt class="text-base text-left font-semibold leading-7 text-gray-900">Report Notes</dt>
          <dd class="mt-5 text-sm text-left leading-6 text-gray-700 sm:col-span-1 sm:mt-0 sm:-ml-1">
            <button (click)="openNewInternalNoteDialog('report')" class="button-small hover:bg-gray-200 rounded-full">
              <ng-icon name='heroPlus' size='18' class='text-gray-500'></ng-icon>
            </button>

            <button (click)="addGenericNoteDialog()" class="ml-2" class="button-small hover:bg-gray-200 rounded-full">
              <ng-icon name="heroSquaresPlus" size="18" class="text-gray-500"></ng-icon>
            </button>
          </dd>

        </div>

        <div class="bg-white py-2 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-3 min-h-10">
          <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <p *ngIf="inspection.notes.length === 0">There are no report notes</p>
            <ul>
              <li *ngFor="let note of inspection.notes" class="flex py-2">

                <div class="mr-5">
                  <ng-icon name="heroChatBubbleBottomCenterText" size="18" class="text-gray-500"></ng-icon>
                </div>

                <span class="text-sm font-medium text-gray-700 leading-normal">{{ note }}</span>

                <span class="self-end ml-auto">
                  <button (click)="deleteNote(note, 'report')"
                    class="hover:bg-gray-200 rounded-full p-2 h-8 w-8 flex items-center justify-center">
                    <ng-icon name="heroTrash" size="18" class="text-gray-500"></ng-icon>
                  </button>
                </span>
              </li>
            </ul>
          </dd>
        </div>

      </dl>
    </div>
  </div>

  <hr>

  <!-- Report -->
  <div class="grid grid-cols-12 mb-8">

    <div class="col-span-12 mt-6">
      <dl>

        <div class="bg-white py-2 sm:grid sm:grid-cols-6 sm:gap-0 sm:px-3 min-h-10">
          <dt class="text-base text-left font-semibold leading-7 text-gray-900">Report</dt>
        </div>

        <div class="bg-white py-2 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-3 min-h-10">
          <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <ul>
              <li *ngFor="let report of inspection.reports" class="py-2">
                <div class="flex w-full items-center justify-center">
                  <div class="mr-5 flex items-center ">
                    <ng-icon name="heroDocumentChartBar" size="18" class="text-gray-500"></ng-icon>
                  </div>

                  <div class="w-full grid grid-cols-12 text-sm font-medium text-gray-500 leading-normal">
                    <div class="col-span-4 text-gray-800 flex items-center">
                      <span>{{ report.name }}</span>
                    </div>

                    <div class="col-span-7">
                      <mat-chip-listbox matListItemLine *ngIf="report.notifications">
                        <mat-chip class="small-chip">
                          Sent: {{ report.notifications[0].to }}
                        </mat-chip>
                        <mat-chip class="small-chip">
                          Status: {{ report.notifications[0].status }}
                        </mat-chip>
                        <mat-chip color="accent" class="small-chip">
                          Opened: {{ report.notifications[0].opens }}
                        </mat-chip>
                      </mat-chip-listbox>
                    </div>
                  </div>

                  <div class="self-end ml-auto">
                    <button [matMenuTriggerFor]="menu"
                      class="action-button hover:bg-gray-200 rounded-full p-2 h-8 w-8 flex items-center justify-center">
                      <span class="sr-only">Open options</span>
                      <ng-icon name="heroEllipsisVertical" size="18" class="text-gray-500"></ng-icon>
                    </button>

                    <mat-menu #menu="matMenu" class="inline-flex flex-col min-w-40 bg-white rounded-md">
                      <button cdk-menu-item (click)="showSendConfirmationDialog(report)"
                        class="action-button w-full px-3 py-3 gap-2 text-sm leading-6 text-gray-900 hover:bg-gray-100 flex flex-row items-center justify-start">
                        <ng-icon name="heroPaperAirplane" size="18"></ng-icon>
                        <span>Send</span>
                      </button>
                      <a cdk-menu-item [href]="report.url"
                        class="action-button w-full px-3 py-3 gap-2 text-sm leading-6 text-gray-900 hover:bg-gray-100 flex flex-row items-center justify-start">
                        <ng-icon name="heroArrowDownTray" size="18"></ng-icon>
                        <span>Download</span>
                      </a>
                      <button cdk-menu-item (click)="DeleteReportHandle(report)"
                        class="action-button w-full px-3 py-3 gap-2 text-sm leading-6 text-gray-900 hover:bg-gray-100 flex flex-row items-center justify-start">
                        <ng-icon name="heroTrash" size="18"></ng-icon>
                        <span>Delete</span>
                      </button>
                    </mat-menu>
                  </div>
                </div>

                <div class="ml-10">
                  <div class="text-gray-500">{{ report.authorName }} | {{report.createdAt | date}}</div>
                </div>
              </li>
            </ul>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</div>