<div class="p-10 rounded-xl overflow-y-auto" (keydown.enter)="submit()">
  <!-- Dialog title -->
  <h5 class="text-lg font-semibold mb-4 text-gray-900">Edit Glider Count Details</h5>
  <form [formGroup]="loadTestForm" class="space-y-4">
    <!-- Checkbox for Red Gliders -->
    <div class="relative flex items-start mb-4">
      <div class="flex h-6 items-center">
        <input id="redGliders" type="checkbox" formControlName="hasRedGliders"
          class="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-600">
      </div>
      <div class="ml-3 text-sm leading-6">
        <label for="redGliders" class="font-medium text-gray-900">Red Gliders</label>
      </div>
    </div>

    <!-- Input for Maximum Red Gliders Per Track -->
    <div *ngIf="loadTestForm.get('hasRedGliders').value" class="mb-4">
      <label for="maximumRedGlidersPerTrack" class="block text-sm font-medium text-gray-900">
        Maximum Red Gliders Per Track
      </label>
      <input type="number" formControlName="maximumRedGlidersPerTrack" id="maximumRedGlidersPerTrack"
        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
    </div>

    <!-- Checkbox for Blue Gliders -->
    <div class="relative flex items-start mb-4">
      <div class="flex h-6 items-center">
        <input id="blueGliders" type="checkbox" formControlName="hasBlueGliders"
          class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600">
      </div>
      <div class="ml-3 text-sm leading-6">
        <label for="blueGliders" class="font-medium text-gray-900">Blue Gliders</label>
      </div>
    </div>

    <!-- Input for Maximum Blue Gliders Per Track -->
    <div *ngIf="loadTestForm.get('hasBlueGliders').value" class="mb-4">
      <label for="maximumBlueGlidersPerTrack" class="block text-sm font-medium text-gray-900">
        Maximum Blue Gliders Per Track
      </label>
      <input type="number" formControlName="maximumBlueGlidersPerTrack" id="maximumBlueGlidersPerTrack"
        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
    </div>

    <!-- Checkbox for Grey Gliders -->
    <div class="relative flex items-start mb-4">
      <div class="flex h-6 items-center">
        <input id="greyGliders" type="checkbox" formControlName="hasGreyGliders"
          class="h-4 w-4 rounded border-gray-300 text-gray-600 focus:ring-gray-600">
      </div>
      <div class="ml-3 text-sm leading-6">
        <label for="greyGliders" class="font-medium text-gray-900">Grey Gliders</label>
      </div>
    </div>

    <!-- Input for Maximum Grey Gliders Per Track -->
    <div *ngIf="loadTestForm.get('hasGreyGliders').value" class="mb-4">
      <label for="maximumGreyGlidersPerTrack" class="block text-sm font-medium text-gray-900">
        Maximum Grey Gliders Per Track
      </label>
      <input type="number" formControlName="maximumGreyGlidersPerTrack" id="maximumGreyGlidersPerTrack"
        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
    </div>

    <!-- Checkbox for Yellow Gliders -->
    <div class="relative flex items-start mb-4">
      <div class="flex h-6 items-center">
        <input id="yellowGliders" type="checkbox" formControlName="hasYellowGliders"
          class="h-4 w-4 rounded border-gray-300 text-yellow-600 focus:ring-yellow-600">
      </div>
      <div class="ml-3 text-sm leading-6">
        <label for="yellowGliders" class="font-medium text-gray-900">Yellow Gliders</label>
      </div>
    </div>

    <!-- Input for Maximum Yellow Gliders Per Track -->
    <div *ngIf="loadTestForm.get('hasYellowGliders').value" class="mb-4">
      <label for="maximumYellowGlidersPerTrack" class="block text-sm font-medium text-gray-900">
        Maximum Yellow Gliders Per Track
      </label>
      <input type="number" formControlName="maximumYellowGlidersPerTrack" id="maximumYellowGlidersPerTrack"
        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
    </div>

    <!-- Checkbox for White Gliders -->
    <div class="relative flex items-start mb-4">
      <div class="flex h-6 items-center">
        <input id="whiteGliders" type="checkbox" formControlName="hasWhiteGliders"
          class="h-4 w-4 rounded border-gray-300 text-gray-600 focus:ring-gray-600">
      </div>
      <div class="ml-3 text-sm leading-6">
        <label for="whiteGliders" class="font-medium text-gray-900">White Gliders</label>
      </div>
    </div>

    <!-- Input for Maximum White Gliders Per Track -->
    <div *ngIf="loadTestForm.get('hasWhiteGliders').value" class="mb-4">
      <label for="maximumWhiteGlidersPerTrack" class="block text-sm font-medium text-gray-900">
        Maximum White Gliders Per Track
      </label>
      <input type="number" formControlName="maximumWhiteGlidersPerTrack" id="maximumWhiteGlidersPerTrack"
        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
    </div>
  </form>

  <!-- Dialog Actions -->
  <div class="flex justify-end space-x-2 mt-4">
    <button type="button"
      class="border border-gray-300 text-gray-700 rounded-md px-4 py-2 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
      (click)="onNoClick()">Cancel</button>
    <button type="button"
      class="bg-blue-600 text-white rounded-md px-4 py-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      (click)="submit()">Submit</button>
  </div>
</div>
